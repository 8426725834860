import { MAINTAIN_PERMISSION, STERILIZATION_AUDITS_AUDITGROUP } from "@kraftheinz/common";
import { D as DATE_FORMAT } from "./audit.others.js";
import { A as AUDIT_EVENT_TYPE, a as AUDIT_STATUS_COLORS, b as AUDIT_STATUS_TEXTS } from "./audit.status.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Audit Group", "search-by": "GroupName", "ph-search-by": "Search by Group Name" }, scopedSlots: _vm._u([{ key: "action", fn: function(ref) {
    var edit = ref.edit;
    var record = ref.record;
    var toggleActive = ref.toggleActive;
    return [_c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v(" " + _vm._s(record.isInactive ? "Activate" : "Deactivate") + " ")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "list__action-toggle-active mr-2", attrs: { "icon": record.isInactive ? "eye-invisible" : "eye", "size": "small", "type": "link" }, on: { "click": function($event) {
      return toggleActive(record);
    } } })], 1), _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Detail")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "right-circle", "size": "small", "type": "link" }, on: { "click": function($event) {
      return edit(record);
    } } })], 1)];
  } }]) }, [_c("url-field", { key: "GroupName", attrs: { "base-url": "/sterilization-audits/audit-groups", "data-index": "groupName", "title": "Group Name", "sorter": true, "clickable": _vm.can(_vm.permissions.maintain) } }), _c("due-date-field", { key: "NextAuditDate", attrs: { "data-index": "nextAuditDate", "title": "Audit Due Date", "sorter": true, "parse": _vm.parseAuditDueDate, "event-type": _vm.getEventType } }), _c("tag-field", { key: "Status", attrs: { "data-index": "status", "title": "Status", "sorter": true, "color": _vm.getStatusColor, "parse": _vm.parseStatus } }), _c("text-field", { key: "NoOfLinks", attrs: { "data-index": "noOfLinks", "title": "No of Links", "align": "right", "sorter": true } }), _c("text-field", { key: "AuditFrequency", attrs: { "data-index": "auditFrequency", "title": "Audit Frequency", "align": "right", "sorter": true } }), _c("text-field", { key: "CanSize", attrs: { "data-index": "canSize", "title": "Container Size", "sorter": true } }), _c("text-field", { key: "Steriliser", attrs: { "data-index": "steriliser", "title": "Steriliser", "sorter": true } }), _c("text-field", { key: "NextAuditFillConditions", attrs: { "data-index": "nextAuditFillConditions", "title": "Next Audit Fill Conn", "sorter": true } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListAuditGroups",
  inject: ["can"],
  data() {
    return {
      permissions: {
        maintain: MAINTAIN_PERMISSION
      }
    };
  },
  methods: {
    parseAuditDueDate(value) {
      if (!value)
        return "";
      return this.$moment(value).format(DATE_FORMAT);
    },
    getEventType(value) {
      const f = "YYYY-MM-DD";
      const nextAuditDate = this.$moment(value).format(f);
      const today = this.$moment().format(f);
      const next30DaysIs = this.$moment().add(30, "days").format(f);
      if (this.$moment(nextAuditDate).isBefore(today))
        return AUDIT_EVENT_TYPE.OVERDUE;
      if (this.$moment(nextAuditDate).isSame(today, "day") || this.$moment(nextAuditDate).isBetween(today, next30DaysIs))
        return AUDIT_EVENT_TYPE.UPCOMING;
      return AUDIT_EVENT_TYPE.NOT_URGENT;
    },
    getStatusColor(value) {
      return AUDIT_STATUS_COLORS[value];
    },
    parseStatus(value) {
      return AUDIT_STATUS_TEXTS[value];
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListAuditGroups = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-audit-groups" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "page": _vm.page, "name": "sterilization.audit-groups", "api-url": _vm.apiUrl, "create-route": "/sterilization-audits/audit-groups/create", "filter-route": "/sterilization-audits/audit-groups/advanced-search", "edit-route": "/sterilization-audits/audit-groups/:id" } }, [_c("list-audit-groups")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "AuditGroup",
  components: {
    ListAuditGroups
  },
  data() {
    return {
      page: STERILIZATION_AUDITS_AUDITGROUP,
      apiUrl,
      itemsMenu: [
        {
          key: "dataentry",
          title: "Data Entry",
          path: ""
        },
        {
          key: "auditgroups",
          title: "Audit Group",
          path: "/sterilization-audits/audit-groups"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
